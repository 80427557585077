<template>
  <meta charset="utf-8">
	<div class="container">
		<Header :title="'Template Editor'" />
    <br>
		<div class="row">
			<div class="col-6">
				<label id="systemtext" for="system">Mailsystem</label>
				<select id="system" class="form-control" v-model="system">
					<option>-----</option>
					<option v-for="mailsystem in mailsystems" :key="mailsystem.name" :value="mailsystem">
						{{ mailsystem.name }}
					</option>
				</select>
			</div>
			<div class="col-6">
				<label id="disttext"  for="dist">Distributor</label>
				<select id="dist" class="form-control" v-model="distributor">
					<option>-----</option>
					<option v-for="distributor in system.distributors" :key="distributor.name" :value="distributor">
						{{ distributor.name }}
					</option>
				</select>
			</div>
		</div>
		<div class="row">
			<div class="col-3">
				<div class="input-group">
					<span id="kamp_span" class="input-group-text">
						<a v-if="kampid" :href="'https://jira-tpng.atlassian.net/browse/KAMP-' + kampid" target="_blank" >Kamp-</a>
						<span v-else id="kamp_span">Kamp-</span>
					</span>
					<input type="text" class="form-control" id="kampid" name="kampid" placeholder="12345" aria-label="Kamp-ID" aria-describedby="basic-addon1" v-model="kampid">
				</div>
			</div>
			<div class="col-9">
				<input type="text" class="form-control" id="preheader" placeholder="Preheader" v-model="pretext" />
			</div>
		</div>
		<div class="row options">
			<div class="col-2 center">
				<label for="advice">TD Advice</label>
					<input type="checkbox" id="advice" name="advice"  v-model="advice">
			</div>
			<div class="col-1 center">
				<label for="advice">Umlaute</label>
				<input type="checkbox" id="umlaute" name="umlaute"  v-model="umlaute">
			</div>
			<div class="col-1 center">
				<label for="advice">Headless</label>
				<input type="checkbox" id="headless" name="headless" v-model="headless">
			</div>
			<!--div class="col-auto">
				<label><i class="fas fa-adjust"></i> Random</label>
				<input type="number" min="0" max="10" step="1" placeholder="0" id="encryptRange" class="form-control" name="encryptRange" v-model="encrypt">
			</div-->
			<div class="col-4 mr-auto">
				<label><i class="fas fa-search"></i> Search and replace</label>
				<div class="input-group">
					<input type="text" id="search" class="form-control" name="search" v-model="search">
					<input type="text" id="replace" class="form-control" name="replace" v-model="replaceing">
					<button id="sarchreplace" class="btn btn-outline-dark" @click="reset()"><i class="fas fa-sync"></i></button>
					<button id="sarchreplace" class="btn btn-outline-dark" @click="replace()">Ok</button>
				</div>
			</div>
			<div class="col-2 mr-auto trackinglinks" v-if="knowntrackings">
				<label><i class="fas fa-terminal"></i>Trackinglinks</label>
				<select id="trackinglinks" v-model="trackingreplacer">
					<option value="random">Random</option>
					<option v-for="link in trackinglinks.new" :key="link" :value="link">{{ link }}</option>
				</select>
			</div>
			<div class="col-1">
				<button id="reload" class="btn btn-danger" @click="refresh()"><i class="fas fa-sync"></i></button>
			</div>
			<div class="col-1">
				<button id="ctc" class="btn btn-success" @click="addToClipboard()">Copy</button>
			</div>

		</div>
		<div class="row">
      <lable class="col-auto" @click="color = !color" v-if="system.customfields || distributor.customfield || system.name === 'Mailsolution'">
        Colors
        <i class="fas fa-chevron-down" v-if="!color"></i>
        <i class="fas fa-chevron-up" v-else></i>
      </lable>
    </div>
    <div class="row" v-if="color === true">
			<div class="col-auto">
				<label>Header Footer Backgroundcolor</label>
				<input type="color" id="bgcolor" name="bgcolor" v-model="bgcolor" />
        <label style="margin-left: 30px;">( default = transparent )</label>
			</div>
			<div class="col-auto">
				<label>Header Footer Fontcolor</label>
				<input type="color" id="fontcolor" name="fontcolor" v-model="fontcolor" />
			</div>
		</div>
    <hr />
		<div class="row" v-if="system.customfields || distributor.customfields">
			<div class="col-auto">
				<lable for="custom" @click="custom = !custom">
					Customfields
					<i class="fas fa-chevron-down" v-if="!custom"></i>
					<i class="fas fa-chevron-up" v-else></i>
				</lable>
			</div>
		</div>
    <div class="row customfields" v-if="distributor.customfields && custom === true && system.name === 'Mailsolution'">
      <div id="customfields_var_ms" class="col-auto" v-for="customfield in distributor.customfields" :key="customfield.name" @click="replaceing = customfield.variable">
        {{ customfield.name }}<br>
        {{ customfield.variable }}
      </div>
    </div>
    <div v-if="system.customfields || distributor.customfield || system.name === 'Mailsolution'" >
      <hr />
    </div>
    <div class="row customfields" v-if="system.customfields && custom === true">
      <div id="customfields_cf" class="col-auto" v-for="customfield in system.customfields" :key="customfield.name" @click="replaceing = customfield.variable">
        {{ customfield.name }}<br>
        {{ customfield.variable }}
      </div>
    </div>
		<div class="row" v-if="system.variables">
			<div class="col-auto">
				<lable for="variable" @click="variable = !variable">
					Variables
					<i class="fas fa-chevron-down" v-if="!variable"></i>
					<i class="fas fa-chevron-up" v-else></i>
				</lable>
			</div>
		</div>
		<div class="row variablefields" v-if="system.variables && variable === true">
			<div  id="customfields_var" class="col-auto" v-for="variable in system.variables" :key="variable.name" @click="addReplace(variable.variable)">
				{{ variable.name }}<br>
				{{ variable.variable }}
			</div>
		</div>
		<hr v-if="system.variables" />
		<div class="row" v-if="distributor">
			<div class="col-12">
				<textarea id="import" v-model="content"/>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<div class="previepreheader" v-if="pretext && template">
						Preheader-Text: {{ pretext }}
					<hr />
				</div>
				<iframe :srcdoc="template" :class=" { active: template } "></iframe>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "../components/Header.vue";
import Mustache from "mustache";
import data from "../data/db.json";
import { ref, computed } from "vue";
import {headerdom, headertemplate} from "../components/mailheader.js";
import {footerdom, footertemplate} from "../components/mailfooter.js";
import {predom, prestyle} from "../components/mailpreheader.js";
import characters from "../components/replace.js";


const container = {
	components: {
		Header
	},
	setup() {
		const defaultHTML = "<html>\n<head>\n</head>\n<body>\n</body>\n</html>";

		const advice = ref(true);
		const umlaute = ref(true);
		const headless = ref(false);
		const kampid = ref("");
		const mailsystems = data.mailsystem;
		const trusteddialog = data.trusteddialog;
		const trackinglinks = data.trackinglinks;
		const knowntrackings = ref(false);
		const trackingreplacer = ref("random");
		const system = ref("");
		const distributor = ref(false);
		const search = ref("");
		const replaceing = ref("");
		const custom = ref(false);
		const variable = ref(false);
		const content = ref("");
		const pretext = ref("");
		const bgcolor = ref("");
    const color = ref(false);

		const signout = ref(() => {
			return "https://abmeldung.dein-newsletter.com/?verteiler=" +
			(distributor.value.tdhead ? distributor.value.tdhead.toLowerCase() : distributor.value.name.toLowerCase()) +
			"&email=" +
			system.value.variables.find(x => x.name === 'email').variable +
			"&kampID=Kamp-" +
			kampid.value;
		});

		const fontcolor = ref(() => {return distributor.value.style.color;});

		/**
		 * Create Header
		 */



		const header = ref({
			"content": () => {
				headerdom.texts.firm = distributor.value.name;
				headerdom.links.link1 = system.value.variables.find(x => x.name === 'htmlview').variable;
				headerdom.links.link2 = signout.value;
				headerdom.style.background = bgcolor.value;
				headerdom.style.color = fontcolor.value;
				return headerdom;
			},
			"template": headertemplate,
		});

		/**
		 * Create Footer
		 */
		const footer = ref({
			"content": () => {
				footerdom.texts.firm = distributor.value.name;
				footerdom.link1 = signout.value;
				footerdom.imprintlink = distributor.value.imprint;
				footerdom.style.background = bgcolor.value;
				footerdom.style.color = fontcolor.value;
				return footerdom;
			},
			"template": footertemplate
		});

		/**
		 * Create Preheader
		 */
		const preheader = computed(() => {
			return {
				"wrapper": "\n<!-- Preheader Online_Mailing_Block -->",
				"style": "\n<!--- CSS-Style für Preheader -->" + prestyle + "\n<!--- Ende CSS-Style für Preheader -->",
				"content": predom(pretext.value)
			};
		});

		/**
		 * Create TD-Header
		 */
		const tdhead = computed(() => {
			if(!distributor.value.tdhead) {
				return {
					head: "",
					body: ""
				};
			}
			return {
				"head": Object.values(trusteddialog).find(x => x.name === distributor.value.tdhead).head,
				"body": Object.values(trusteddialog).find(x => x.name === distributor.value.tdhead).body
			};
		});

		/**
		 * Create Template
		 */
		const template = computed(() => {
			if (content.value) {
				let value = content.value;
				const isHtml = content.value.includes("<html");
				if (!isHtml) {
					value = `${defaultHTML.split(/<\/body>/g)[0]}<center>${value}</center></body></html>`;
				}
				if (umlaute.value) {
					Object.keys(characters).forEach(function(x) {
						value = value.replaceAll(x, characters[x]);
					});
				}

				/**
				 * Regex's for the if-conditions below
				 */
				let tdregex = /<!-- TD-Start -->((.|\n)*?)<!-- TD-End -->/g;
				let preheaderregex = /<!-- Preheader Online_Mailing_Block -->((.|\n)*?)<!-- Preheader Online_Mailing_Block -->/g;
				let tpngregex = /<!--\[Online_Mailing_Block\]-->((.|\n)*?)<!--\[\/Online_Mailing_Block\]-->/g;
				let metaregex = /<!--- Meta-Angabe für Header-Grafik -->((.|\n)*?)<!--- Ende Meta-Angabe für Header-Grafik -->/g;
				let csspreheaderregex = /<!--- CSS-Style für Preheader -->((.|\n)*?)<!--- Ende CSS-Style für Preheader -->/g;
				let cssHeadregex = /<!--- CSS-Style für Header-Grafik -->((.|\n)*?)<!--- Ende CSS-Style für Header-Grafik -->/g;
				let scriptregex = /<script[\s\S]*?>[\s\S]*?<\/script>/gi;
				let matchingPreheader = "";

				/**
				 * If-conditions, search and replace if exists
				 */

				// TD element exists?
				if (content.value.search(tdregex) >= 0) {
					value = value.replace(tdregex, "");
				}

				// TPNG element exists?
				if (content.value.search(tpngregex) >= 0) {
					value = value.replace(tpngregex, "");
				}

				// Preheader element exists? If it exists move the preheader to the top of the dom body
				if (content.value.search(preheaderregex) >= 0 && pretext.value.length > 0) {
					value = value.replace(preheaderregex, "");
				} else if ( content.value.search(preheaderregex) >= 0 && !pretext.value.length > 0) {
					matchingPreheader = content.value.match(preheaderregex);
					value = value.replace(preheaderregex, "");
				}
				// Meta element exists?
				if (content.value.search(metaregex) >= 0) {
					value = value.replaceAll(metaregex, "");
				}

				// Css element exists?
				if (content.value.search(cssHeadregex) >= 0) {
					value = value.replaceAll(cssHeadregex, "");
				}

				// Css element for the preheader exists?
				if (content.value.search(csspreheaderregex) >= 0) {
					value = value.replaceAll(csspreheaderregex, "");
				}

				// Search if the template contains javascript and retunrns an alert
				if (content.value.search(scriptregex) >= 0 || content.value.includes("<script") || content.value.includes("<javascript")) {
					alert("Javascript detected! Please check your template and/or get in contact with the developer!");
				}

				/**
				 * Seperate the content behind the beginning body tag
				 */
				let precontent = value.split(/<body\b[^>]*>/g)[1];

				/**
				 * Create a new dome, based on the selections and values.
				 */

				let newDom = {
					"head": {
						"content": value.split(/<\/head>/g)[0],
						"preheader": preheader.value.style,
						"end": "</head>"
					},
					"body": {
						"start": value.match(/<body\b[^>]*>/g)[0],
						"preheader": (matchingPreheader[0] && matchingPreheader[0].length > 0) ? matchingPreheader[0] : preheader.value.wrapper + preheader.value.content + preheader.value.wrapper,
						"tpngheader": headless.value ? "" : Mustache.render(header.value.template, header.value.content()),
						"content":  precontent.split(/<\/body>/g)[0],
						"tpngfooter": Mustache.render(footer.value.template, footer.value.content()),
						"end": "</body></html>"
					}
				};

				/**
				 * Combine all replacements and addons to one result
				 */
				let result = (
					newDom.head.content +
					(advice.value && distributor.value.tdhead ? tdhead.value.head.meta.replace(/'/g, '"') : "") +
					newDom.head.preheader +
					(advice.value && distributor.value.tdhead ? tdhead.value.head.style.replace(/'/g, '"') : "") +
					newDom.head.end +
					newDom.body.start +
					(advice.value && distributor.value.tdhead ? tdhead.value.body.div.replace(/'/g, '"') : "") +
					newDom.body.preheader +
					newDom.body.tpngheader +
					(advice.value && distributor.value.tdhead ? "<!-- TD-Start -->" +  tdhead.value.body.advice.replace(/'/g, '"') + "<!-- TD-End -->": "") +
					newDom.body.content +
					newDom.body.tpngfooter +
					newDom.body.end
				);


				/**
				 * Removes old tracking codes
				 */
				trackinglinks.old.forEach(function(x) {
					if (result.includes(x)) {
						knowntrackings.value = true;
						if (trackingreplacer.value === "random") {
							result = result.replaceAll(x, trackinglinks.new[Math.floor(Math.random()*trackinglinks.new.length)]);
						} else {
							result = result.replaceAll(x, trackingreplacer.value);
						}
					}
				});
				return result;
			}
			else {
				return defaultHTML.value;
			}
		});

		/**
		 * Replace a search string by a replacing string in template cntent
		 */
		function replace() {
			this.content = this.content.replaceAll(this.search, this.replaceing);
		}

		/**
		 * Add replacing templates to the replacing input.
		 */
		function addReplace(v) {
			if(this.replaceing && this.replaceing.length > 0)
			{
				this.replaceing +=  " " + v;
			}
			else {
				this.replaceing = v;
			}
		}
		/**
		 * Add reset function for replacing field
		 */
		function reset() {
			this.replaceing = "";
		}
		/**
		 * Save current edited template to the clipboards
		 */
		function addToClipboard() {
			console.log(template.value);
			let context = placeholder(template.value);
			navigator.clipboard.writeText(context);
		}

		/**
		 * Replace a search string by a replacing string in template content
		 */

		function placeholder(temp) {
			const customfields = system.value.customfields || distributor.value.customfields;
			const variables = system.value.variables;
			let template = temp;

			let searchCustomfields = [];
			let searchVariable = [];

			if (typeof customfields !== "undefined" && customfields.length > 0 ) {
				customfields.forEach(x => {
					if (template.search(x.ttag) >= 0) {
						searchCustomfields.push(x.ttag);
					}
				});
			} else {
				console.log(customfields);
			}

			if (typeof variables !== "undefined" && variables.length > 0) {

				variables.forEach(y => {
					console.log(y);
					if(template.search(y.ttag) >= 0) {
						console.log(y.ttag);
						searchVariable.push(y.ttag);
					}
				});
			} else {
				console.log(variables);
			}
			console.log("searchVariable", searchVariable);
			if (searchCustomfields.length > 0) {
				searchCustomfields.forEach( x => {
					let placeholder = customfields.find(o => {
						if (o.ttag === x) {
							return o.variable;
						} else {
							return "";
						}
					});
					template = template.replaceAll(x, placeholder.variable);
				});
			} else {
				console.log("no matching found");
			}
			if (searchVariable.length > 0) {
				searchVariable.forEach( y => {
					console.log("searchVariable", y);
					let placeholder = variables.find(o => {
						if (o.ttag === y) {
							return o.variable;
						}
						return "";
					});
					template = template.replaceAll(y, placeholder.variable);
				});
			} else {
				console.log("no matching found");
			}
			return template;
		}
		/**
		 *  The special function for the special button for the special wish of the very special Stefan Wien.
		 */
		function refresh() {
			content.value = "";
			system.value = "";
			distributor.value = "";
			kampid.value = "";
			pretext.value = "";
			replaceing.value = "";
			search.value = "";
			knowntrackings.value = false;
		}

		return {
			content,
			header,
			footer,
			template,
			mailsystems,
			trusteddialog,
			system,
			distributor,
			custom,
			variable,
			kampid,
			advice,
			umlaute,
			headless,
			replace,
			replaceing,
			addReplace,
			search,
			reset,
			tdhead,
			addToClipboard,
			refresh,
			trackinglinks,
			trackingreplacer,
			pretext,
			knowntrackings,
			placeholder,
			bgcolor,
			fontcolor,
      color
		};
	},
};

export default container;
</script>
<style lang="scss" scoped>
	@import "./src/assets/styles/color.scss";
  /* stylelint-disable indentation */
  #system, #dist, #kampid, #kamp_span, #preheader, #search, #replace, #bgcolor, #fontcolor, #trackinglinks  {
    background-color: #b9a28a;
    border-color: #6c553c;

  }
	.row {
		margin-bottom: 8px;
	}

  #customfields_var_ms, #customfields_var, #customfields_cf  {
    border-style: solid;
    border-width: 2px;
    border-color: #6c553c;
   }


  #systemtext, #disttext {
    font-size: 18px;
    font-weight: bold;
    color: #f8e8d2;
    text-shadow: #303030 1px 1px;
  }


	textarea {
		width: 100%;
    background-color: #b9a28a;
		&#import {
			height: 160px;
		}
	}
	label {
		text-align: left;
		display: block;
	}
	iframe {
		&.active {
			width: 100%;
			min-width: 600px;
			height: 600px;
		}
	}
	.customfields div[class*="col-"],
	.variablefields div[class*="col-"] {
		margin: 4px 8px;
		border: 1px solid $tiles;
	}
	.mr-auto {
		margin-right: auto;
	}
	input[type=checkbox] {
		margin: 10px;
		transform: scale(1.5);
	}
	.options div[class*="col-"] > .btn {
		margin: 22px 0 0;
	}
	div[class*="col-"].center {
		& > label {
			text-align: center;
		}
		& > input {
			margin-left: auto;
			margin-right: auto;
		}
	}
	.trackinglinks {
		select {
			padding: 0.375rem 0.75rem;
			height: 38px;
		}
	}
</style>
